import { request4 } from '@/service/request4'
import cache from "@/utils/cache"

export function getUserList(obj) {
  return request4({
    url: '/manager/customer/customers',
    method: 'post',
    data: obj
  })
}

export function getOrderList(obj) {
  return request4({
    url: '/manager/order/orders',
    method: 'post',
    data: obj
  })
}


export function getUserLaiyuan() {
  return request4({
    url: '/manager/admin/get-source',
    method: 'post'
  })
}

export function getShopList(companyId) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/shop-basic/${companyId}/${cashUid}`,
    method: 'post'
  })
}

export function getUserDetail(userId, companyId) {
  return request4({
    url: `/manager/customer/back-customer-detail/${userId}/${companyId}`,
    method: 'post'
  })
}

export function editUserInfo(obj) {
  return request4({
    url: `/manager/customer/update-customer-baisc`,
    method: 'post',
    data: obj
  })
}

export function editUserTag(obj) {
  return request4({
    url: `/manager/customer/update-customer-tag`,
    method: 'post',
    data: obj
  })
}

export function getUsetGenjinList(userId, page, pagesize) {
  return request4({
    url: `/manager/appointment/get-all-customer-adds/${userId}/${page}/${pagesize}`,
    method: 'post'
  })
}

export function getUsetCommrntList(obj) {
  return request4({
    url: `/order/web/getWebEvaluateList`,
    method: 'post',
    data: obj
  })
}

export function updateCommentState(userId, id, showoff) {
  return request4({
    url: `/manager/appointment/update-comment-showoff/${userId}/${id}/${showoff}`,
    method: 'post'
  })
}

export function getUserCardList(obj) {
  return request4({
    url: `/cardItem/web/getUserCardList`,
    method: 'post',
    data: obj
  })
}

export function addUserTag(companyId,userId,newtag) {
  return request4({
    url: `/manager/admin/new-customer-tag/${companyId}/${userId}/${newtag}`,
    method: 'post'
  })
}

export function updateUserTag(userId,otag,newtag) {
  return request4({
    url: `/manager/admin/update-single-tag/${userId}/${otag}/${newtag}`,
    method: 'post'
  })
}

export function addClient(obj) {
  return request4({
    url: `/manager/customer/new-back-customer`,
    method: 'post',
    data:obj
  })
}

export function getCouponList(userId) {
  return request4({
    url: `/manager/money/user-coupons/${userId}`,
    method: 'post'
  })
}
export function getWebUserCouponList(data) {
  return request4({
    url: `/coupon/web/getWebUserCouponList`,
    method: 'post',
    data
  })
}

export function getCardYue(cardNo) {
  return request4({
    url: `/manager/card/card-cash/${cardNo}`,
    method: 'post'
  })
}

export function getCardServeYue(cardNo) {
  return request4({
    url: `/manager/card/user-card-serves/${cardNo}`,
    method: 'post'
  })
}

export function getCardBenjinServeYue(cardNo) {
  return request4({
    url: `/manager/card/user-card-serves/${cardNo}`,
    method: 'post'
  })
}

export function getCardBuyYue(cardNo) {
  return request4({
    url: `/manager/card/chuzhi-zhekou-card/${cardNo}`,
    method: 'post'
  })
}
export function editUserCardExpireTime(obj) {
  return request4({
    url: `/manager/card/editUserCardExpireTime`,
    method: 'put',
    data: obj
  })
}

export function getOrderList2(obj) {
  return request4({
    url: '/manager/order/orders',
    method: 'post',
    data: obj
  })
}

export function getCardDetail2(obj) {
  return request4({
    url: `/manager/card/card-detail`,
    method: 'post',
    data: obj
  })
}
//获取客户销售
export function getCustomerSaleLog(obj) {
  return request4({
    url: `/customer/web/getCustomerSaleLog`,
    method: 'post',
    data: obj
  })
}
//获取治疗方案列表
export function getTreatmentPlanList(id) {
  return request4({
    url: `/treatmentPlan/web/getTreatmentPlanList?userId=${id}`,
    method: 'get'
  })
}
//获取治疗方案
export function getTreatmentPlan(id) {
  return request4({
    url: `/treatmentPlan/web/getTreatmentPlan?id=${id}`,
    method: 'get'
  })
}
//获取后台服务日志列表
export function getWebServeLogList(obj) {
  return request4({
    url: `/serveLog/web/getWebServeLogList`,
    method: 'post',
    data: obj
  })
}
//兑换记录
export function getExchangeCouponRecordList(obj) {
  return request4({
    url: `/coupon/web/getExchangeCouponRecordList`,
    method: 'post',
    data: obj
  })
}
//获取客户分析
export function getCustomerAnalysis(obj) {
  return request4({
    url: `/customer/web/getCustomerAnalysis`,
    method: 'post',
    data: obj
  })
}
//客户来源列表
export function customerSourceList() {
  return request4({
    url: `/manager/customer/customerSourceList`,
    method: 'get'
  })
}
//添加客户来源
export function addCustomerSource(params) {
  return request4({
    url: `/manager/customer/addCustomerSource`,
    method: 'get',
    params
  })
}
//编辑客户来源
export function editCustomerSource(params) {
  return request4({
    url: `/manager/customer/editCustomerSource?id=${params.id}&customerSourceName=${params.customerSourceName}`,
    method: 'PUT',
  })
}
//删除客户来源
export function deleteCustomerSource(params) {
  return request4({
    url: `/manager/customer/deleteCustomerSource`,
    method: 'delete',
    params
  })
}
//编辑客户来源
export function changeCustomerSource(data) {
  return request4({
    url: `/customer/web/editCustomerSource`,
    method: 'post',
    data
  })
}